import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  state: {
    loggedInUser:
      localStorage.getItem('userInfo') != null
        ? JSON.parse(localStorage.getItem('userInfo'))
        : null,
    loading: false,
    error: null,
    userProfile: {
      skills: null,
      firstname: null,
      lastname: null,
    },
    isLoaded: null,
    o_CurrentPlanningPeriod: {
      planningPeriodID: null,
      description: null,
      startDate: null,
      endDate: null,
    },
    o_CurrentRole: {
      i_AddressRoleID: null,
      s_Role: null,
    },
    o_CurrentExamEvent: {
      i_ExamEventID: null,
      s_Name: null,
      s_Description: null,
      i_PlanningPeriodID: null,
    },
    a_PlanningPeriods: [],
    s_LastSearchFG: null,
    s_LastSearchCI: null,
    s_LastSearchMC: null,
    i_LastTestID: null,
  },
  getters: {
    loggedInUser: state => state.loggedInUser,
    loading: state => state.loading,
    error: state => state.error,
    getProfile: state => state.userProfile,
    getRoles: state => state.loggedInUser.roles,
    getRoleNames: state => {
      const La_Roles = []
      state.loggedInUser.roles.forEach(role => {
        La_Roles.push(role.s_Role)
      })
      return La_Roles
    },
    getPlanningPeriodID: state => state.o_CurrentPlanningPeriod.planningPeriodID,
    getPlanningPeriod: state => state.o_CurrentPlanningPeriod,
    getCurrentExamEvent: state => state.o_CurrentExamEvent,
    getPlanningPeriods: state => state.a_PlanningPeriods,
    getCurrentRole: state => state.o_CurrentRole,
    getUserID: state => state.loggedInUser.uid,
    getLastSearchFG: state => state.s_LastSearchFG,
    getLastSearchCI: state => state.s_LastSearchCI,
    getLastSearchMC: state => state.s_LastSearchMC,
    getLastTestID: state => state.i_LastTestID,
  },
  mutations: {
    setUser (state, data) {
      state.loggedInUser = data
      state.loading = false
      state.error = null
    },
    setLogout (state) {
      state.loggedInUser = null
      state.loading = false
      state.error = null
      // this.$router.go("/");
    },
    setLoading (state, data) {
      state.loading = data
      state.error = null
    },
    setError (state, data) {
      state.error = data
      state.loggedInUser = null
      state.loading = false
    },
    clearError (state) {
      state.error = null
    },
    setRoles (state, data) {
      state.loggedInUser.roles = data
    },
    setPlanningPeriods (state, data) {
      state.a_PlanningPeriods = data
    },
    setCurrentRole (state, data) {
      state.o_CurrentRole = data
    },
    setProfile (state, data) {
      state.userProfile.skills = data.a_Skills
      state.userProfile.firstname = data.s_Firstname
      state.userProfile.lastname = data.s_Lastname
    },
    setCurrentPlanningPeriod (state, data) {
      state.o_CurrentPlanningPeriod = data
    },
    setCurrentExamEvent (state, data) {
      state.o_CurrentExamEvent = data
    },
    setCurrentPlanningPeriod (state, data) {
      state.o_CurrentPlanningPeriod = data
    },
    setLastSearchFG (state, data) {
      state.s_LastSearchFG = data
    },
    setLastSearchCI (state, data) {
      state.s_LastSearchCI = data
    },
    setLastSearchMC (state, data) {
      state.s_LastSearchMC = data
    },
    setLastTestID (state, data) {
      state.i_LastTestID = data
    },
  },
  actions: {
    login ({ commit }, Ai_UserID) {
      commit('clearError')
      commit('setLoading', true)
      const newUser = {
        uid: Ai_UserID,
        roles: [],
        firstname: null,
        lastname: null,
      }
      localStorage.setItem('userInfo', JSON.stringify(newUser))
      commit('setUser',
        {
          uid: Ai_UserID,
          roles: [], // admin, author, student, teacher, qsk
        },
      )
    },
    setRoles ({ commit }, Aa_Roles) {
      const o_UserInfo = JSON.parse(localStorage.getItem('userInfo'))
      const updatedUser = {
        uid: o_UserInfo.uid,
        roles: Aa_Roles,
      }
      localStorage.setItem('userInfo', JSON.stringify(updatedUser))
      commit('setRoles', Aa_Roles)
    },
    setPlanningPeriods ({ commit }, Aa_PlanningPeriods) {
      commit('setPlanningPeriods', Aa_PlanningPeriods)
    },
    setCurrentRole ({ commit }, Ao_CurrentRole) {
      commit('setCurrentRole', Ao_CurrentRole)
    },
    setProfile ({ commit }, Ao_Profile) {
      /* let o_UserInfo = JSON.parse(localStorage.getItem('userInfo'));
      o_UserInfo.skills = Ao_Profile.skills;
      o_UserInfo.firstname = Ao_Profile.s_Firstname;
      o_UserInfo.lastname = Ao_Profile.s_Lastname;
      localStorage.setItem('userInfo', JSON.stringify(o_UserInfo)); */
      commit('setProfile', Ao_Profile)
    },
    signUserUp ({ commit }, data) {
      commit('setLoading', true)
      commit('clearError')
      firebase
        .auth()
        .createUserWithEmailAndPassword(data.email, data.password)
        .then(user => {
          commit('setLoading', false)

          const newUser = {
            uid: user.user.uid,
          }
          localStorage.setItem('userInfo', JSON.stringify(newUser))
          commit('setUser', newUser)
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          localStorage.removeItem('userInfo')
        })
    },
    signOut ({ commit }) {
      localStorage.removeItem('userInfo')
      commit('setLogout')
    },
    setCurrentPlanningPeriod ({ commit }, data) {
      commit('setCurrentPlanningPeriod', data)
    },
    setCurrentExamEvent ({ commit }, data) {
      commit('setCurrentExamEvent', data)
    },
    setLastSearchFG ({ commit }, data) {
      commit('setLastSearchFG', data)
    },
    setLastSearchCI ({ commit }, data) {
      commit('setLastSearchCI', data)
    },
    setLastSearchMC ({ commit }, data) {
      commit('setLastSearchMC', data)
    },
    setLastTestID ({ commit }, data) {
      commit('setLastTestID', data)
    },
  },
}
