export default {
  state: {
    o_InitialConfig: {
      a_ExpertSkills: [
        {
          i_SkillID: 1,
          s_Name: 'CI',
        },
        {
          i_SkillID: 2,
          s_Name: 'FG',
        },
        {
          i_SkillID: 3,
          s_Name: 'Erfahren',
        },
        {
          i_SkillID: 22,
          s_Name: 'Bewertung',
        },
      ],
    },
  },
  getters: {
    getInitialConfig: state => state.o_InitialConfig,
  },
  mutations: {
    setInitialConfig (state, data) {
      state.o_InitialConfig = data
    },
  },
  actions: {
    setInitialConfig ({ commit }, data) {
      commit('setInitialConfig', data)
    },
  },
}
