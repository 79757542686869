import Worker from 'worker-loader!./worker'
import * as Comlink from 'comlink'

const worker = Comlink.wrap(new Worker())

export const get = worker.get
export const post = worker.post
export const del = worker.del
export const getFile = worker.getFile
export const getToken = worker.getToken
export const refreshToken = worker.refreshToken
export const isTokenNull = worker.isTokenNull
export const getUserPersonID = worker.getUserPersonID
export const isSuperAdmin = worker.isSuperAdmin
