const state = {
  i_FGQuestionsPerPage: -1,
  i_CIQuestionsPerPage: -1,
}

const getters = {
  getFGQuestionsPerPage: state => state.i_FGQuestionsPerPage,
  getCIQuestionsPerPage: state => state.i_CIQuestionsPerPage,
}

const actions = {
  changeQuestionsPerPage ({ commit },Ao_Options) {
    if(Ao_Options.i_QuestionsTypID == 1){
      commit('updateCIQuestionsPerPage', Ao_Options.i_itemsPerPage)
    }
    else if(Ao_Options.i_QuestionsTypID == 2){
      commit('updateFGQuestionsPerPage', Ao_Options.i_itemsPerPage)
    }
  },
}

const mutations = {
  updateCIQuestionsPerPage: (state, AMi_QuestionsPerPage) => {
    state.i_CIQuestionsPerPage = AMi_QuestionsPerPage;
  },
  updateFGQuestionsPerPage: (state, AMi_QuestionsPerPage) => {
    state.i_FGQuestionsPerPage = AMi_QuestionsPerPage;
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}