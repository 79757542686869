export const config = {
  firebaseSettings: {
    apiKey: 'AIzaSyB5J-4ANPidBHK8_7fuBVPoHitxK9GPYhk',
    authDomain: 'gull-vue.firebaseapp.com',
    databaseURL: 'https://gull-vue.firebaseio.com',
    projectId: 'gull-vue',
    storageBucket: '',
    messagingSenderId: '418473219984',
    appId: '1:418473219984:web:83d917d20e29e506',
  },
  roles: [
    'student', 'teacher', 'author', 'qsk', 'admin',
  ],
  attribute: {
    i_Experte2022: 67,
    i_NichtExperte2022: 106,
  },
  idSettings: {
    testType: {
      i_CI: 1,
      i_FG: 2,
      i_MC: 3
    },
  },
}
