import qs from 'qs'
import { get } from '../worker/worker-api'
import store from '../store'
import router from '../router'

export default {
    store,
    install (Vue, options) {
        Vue.prototype.$myStore = store
        Vue.prototype.$myRouter = router;
        Vue.prototype.$smt = {
            log: (text) => console.info(text),
            redirect: () => {
                const queryString = {
                    client_id: process.env.VUE_APP_OAUTH_CLIENT_ID,
                    redirect_uri: process.env.VUE_APP_OAUTH_CLIENT_REDIRECT,
                    response_type: 'code',
                    scope: '',
                }
                window.location.href = `${process.env.VUE_APP_OAUTH_AUTH_SERVER}/oauth/authorize?${qs.stringify(queryString)}`
            },
            redirectAdmin: () => {
                const queryString = {
                    client_id: process.env.VUE_APP_OAUTH_CLIENT_ID,
                    redirect_uri: process.env.VUE_APP_OAUTH_CLIENT_REDIRECT,
                    response_type: 'code',
                    scope: 'admin-all',
                }
                window.location.href = `${process.env.VUE_APP_OAUTH_AUTH_SERVER}/oauth/authorize?${qs.stringify(queryString)}`
            },
            isRouteFree (Ao_Route) {
                let s_Url = Ao_Route.path
                const freeRoutes = [
                    '/callback',
                    '/app/sessions/register',
                    '/app/sessions/validate-register',
                    '/app/sessions/reset-password-request',
                    '/app/sessions/user/reset-password',
                ]

                if (freeRoutes.includes(Ao_Route.path)) return true
                for (const param in Ao_Route.params) {
                    s_Url = s_Url.replace('/' + Ao_Route.params[param], '')
                }
                if (freeRoutes.includes(s_Url)) return true
                return false
            },
            logout: () => {
                window.location.href = `${process.env.VUE_APP_OAUTH_AUTH_SERVER}/logout/${process.env.VUE_APP_OAUTH_CLIENT_ID}`
            },
            login: async () => {
                return await get(`${process.env.VUE_APP_SMT_API_URL}/user`, null)
            },
            getAddressRoleID (As_Role) {
                const a_Role = Vue.prototype.$myStore.getters.getRoles.filter(role => role.s_Role === As_Role)
                if (a_Role.length > 0) {
                    return a_Role[0].i_AddressRoleID
                } else return null
            },
            getRoleFromUrl (As_URL) {
                const { route } = Vue.prototype.$myRouter.resolve(As_URL);
                return route.meta.role;
            },
            findCurrentPeriod(Aa_PlanningPeriods, As_CurrentRole) {
                let La_RolePlanningPeriods = Aa_PlanningPeriods.filter(
                  period => period.role === As_CurrentRole
                );
                let La_DatePlanningPeriods = La_RolePlanningPeriods.map(
                  period => {
                    period.startDate = new Date(period.startDate);
                    return period;
                  }
                );
                return La_DatePlanningPeriods.reduce(
                  (periodA, periodB) => periodA.startDate > periodB.startDate ? periodA : periodB
                );
            },
            getSwissDate(As_DateTime) {
                let Ls_Date;
                if(As_DateTime === '1900-12-01T12:13:14.000') return null;
                try {
                    let Lo_Date = new Date(As_DateTime);
                    let Li_Month = Lo_Date.getMonth() + 1;
                    Ls_Date = (Lo_Date.getDate() > 9) ? Lo_Date.getDate() : ('0' + Lo_Date.getDate());  
                    Ls_Date += '.';
                    Ls_Date += (Li_Month > 9) ? Li_Month : ('0' + Li_Month)
                    Ls_Date += '.';
                    Ls_Date += Lo_Date.getFullYear();
                } catch {
                    return null;
                }
                
                return Ls_Date;
            }
        }
    },
}