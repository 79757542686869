import qs from 'qs'
export default (to, from, next) => {
  if (
    localStorage.getItem('userInfo') != null &&
    localStorage.getItem('userInfo').length > 0
  ) {
    next()
  } else {
    localStorage.removeItem('userInfo')
    const queryString = {
      client_id: process.env.VUE_APP_OAUTH_CLIENT_ID,
      redirect_uri: process.env.VUE_APP_OAUTH_CLIENT_REDIRECT,
      response_type: 'code',
      scope: 'admin-all',
    }
    window.location.href = `${process.env.VUE_APP_OAUTH_AUTH_SERVER}/oauth/authorize?${qs.stringify(queryString)}`
  }
}
