/*
 * ============================
 * File: index.js
 * Project: Octavia-Admin
 * File Created: Thursday, 9th April 2020 2:11:05 am
 * Author:UILIB
 * AuthorUrl:https://ui-lib.com/
 * -----
 * Last Modified: Saturday, 18th April 2020 9:32:52 pm
 * Modified By: naime hossain (naime.hossain93@gmail.com)
 * -----
 * Copyright 2020 - 2021 UILIB, UILIB
 * ============================
 */

// Imports
import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import authenticate from '@/auth/authenticate'
import authenticateAdmin from '@/auth/authenticate-admin'
import { isTokenNull } from '../worker/worker-api'
import { config } from '../data/config'
import i18n from '../i18n'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: `/${i18n.locale}/app/dashboard`
    },
    {
      path: '/callback',
      component: () => import('@/views/app/sessions/Callback'),
    },
    {
      path: '/:locale',
      component: {
        render(c) { return c('router-view') }
      },
      //redirect: `/${i18n.locale}/app/dashboard`,
      children: [
        // admin
        {
          path: 'admin-ms/sa',
          component: () => import('@/views/app/Index'),
          beforeEnter: authenticateAdmin,
          children: [
            {
              path: 'select-user',
              name: 'select-user',
              component: () => import('@/views/app/sessions/UserListSimulate'),
            },
            {
              path: 'file-manager',
              name: 'sa-file-manager',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'File Manager' }],
              },
              component: () => import('@/views/app/apps/FileManager'),
            },
            {
              path: 'note',
              name: 'sa-note',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'Note' }],
              },
              component: () => import('@/views/app/apps/Note'),
            },
            {
              path: 'link',
              name: 'sa-link',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'Link' }],
              },
              component: () => import('@/views/app/apps/Link'),
            },
            {
              path: 'timetable',
              name: 'sa-timetable',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'Timetable' }],
              },
              component: () => import('@/views/app/apps/Timetable'),
            },
            {
              path: 'presence',
              name: 'sa-presence',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'Presence' }],
              },
              component: () => import('@/views/app/apps/Presence'),
            },
            {
              path: 'absence',
              name: 'sa-absence',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'Absence' }],
              },
              component: () => import('@/views/app/apps/Absence'),
            },
            {
              path: 'workload',
              name: 'sa-workload',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'Workload' }],
              },
              component: () => import('@/views/app/apps/Workload'),
            },
            {
              path: 'availability',
              name: 'sa-availability',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'Availability' }],
              },
              component: () => import('@/views/app/apps/AvailabilityView'),
            },
            {
              path: 'exams',
              name: 'sa-exams',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'Exams' }],
              },
              component: () => import('@/views/app/apps/Exams'),
            },
            {
              path: 'final-marks',
              name: 'sa-final-marks',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'FinalMarks' }],
              },
              component: () => import('@/views/app/apps/FinalMarks'),
            },
            {
              path: 'semester-marks',
              name: 'sa-semester-marks',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'SemesterMarks' }],
              },
              component: () => import('@/views/app/apps/SemesterMarks'),
            },
            {
              path: 'quarter-marks',
              name: 'sa-quarter-marks',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'QuarterMarks' }],
              },
              component: () => import('@/views/app/apps/QuarterMarks'),
            },
            {
              path: 'experience-marks',
              name: 'sa-experience-marks',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'ExperienceMarks' }],
              },
              component: () => import('@/views/app/apps/ExperienceMarks'),
            },
            {
              path: 'intern-marks',
              name: 'sa-intern-marks',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'InternMarks' }],
              },
              component: () => import('@/views/app/apps/InternMarks'),
            },
            {
              path: 'quarter-marks-pma',
              name: 'sa-quarter-marks-pma',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'QuarterMarksPMA' }],
              },
              component: () => import('@/views/app/apps/QuarterMarksPMA'),
            },
            {
              path: 'semester-marks-pma',
              name: 'sa-semester-marks-pma',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'SemesterMarksPMA' }],
              },
              component: () => import('@/views/app/apps/SemesterMarksPMA'),
            },
            {
              path: 'exams-pma',
              name: 'sa-exams-pma',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'ExamsPMA' }],
              },
              component: () => import('@/views/app/apps/ExamsPMA'),
            },
            {
              path: 'final-marks-pma',
              name: 'sa-final-marks-pma',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'FinalMarksPMA' }],
              },
              component: () => import('@/views/app/apps/FinalMarksPMA'),
            },
            {
              path: 'experience-marks-pma',
              name: 'sa-experience-marks-pma',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'ExperienceMarksPMA' }],
              },
              component: () => import('@/views/app/apps/ExperienceMarksPMA'),
            },
            {
              path: 'presence-pma',
              name: 'sa-presence-pma',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'PresencePMA' }],
              },
              component: () => import('@/views/app/apps/PresencePMA'),
            },
            {
              path: 'students-list-pma',
              name: 'sa-students-list-pma',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'StudentsListPMA' }],
              },
              component: () => import('@/views/app/apps/StudentsListPMA'),
            },
            {
              path: 'children',
              name: 'sa-children',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'Children' }],
              },
              component: () => import('@/views/app/apps/Children'),
            },
            {
              path: 'courses',
              name: 'sa-courses',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'Courses' }],
              },
              component: () => import('@/views/app/apps/Courses'),
            },
            {
              path: 'interns',
              name: 'sa-interns',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'Interns' }],
              },
              component: () => import('@/views/app/apps/Interns'),
            },
          ],
        },
        {
          path: 'app/teacher',
          component: () => import('@/views/app/Index'),
          beforeEnter: authenticate,
          children: [
            {
              path: 'expert-profil',
              name: 'expert-profil',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'expert-profil' }],
                role: config.roles[1],
              },
              component: () => import('@/views/app/apps/ExpertProfile'),
            },
            {
              path: 'document-list',
              name: 'document-list',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'ExpertDocuments' }],
                role: config.roles[1],
              },
              component: () => import('@/views/app/apps/ExpertDocuments'),
            },
            {
              path: 'experience-marks',
              name: 'experience-marks-pma',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'ExperienceMarksPMA' }],
                role: config.roles[1],
              },
              component: () => import('@/views/app/apps/ExperienceMarksPMA'),
            },
            {
              path: 'quarter-marks',
              name: 'quarter-marks-pma',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'QuarterMarksPMA' }],
                role: config.roles[1],
              },
              component: () => import('@/views/app/apps/QuarterMarksPMA'),
            },
            {
              path: 'semester-marks',
              name: 'semester-marks-pma',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'SemesterMarksPMA' }],
                role: config.roles[1],
              },
              component: () => import('@/views/app/apps/SemesterMarksPMA'),
            },
            {
              path: 'exams',
              name: 'exams-pma',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'ExamsPMA' }],
                role: config.roles[1],
              },
              component: () => import('@/views/app/apps/ExamsPMA'),
            },
            {
              path: 'final-marks',
              name: 'final-marks-pma',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'FinalMarksPMA' }],
                role: config.roles[1],
              },
              component: () => import('@/views/app/apps/FinalMarksPMA'),
            },
            {
              path: 'timetable',
              name: 'timetable-pma',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'TimetablePMA' }],
                role: config.roles[1],
              },
              component: () => import('@/views/app/apps/TimetablePMA'),
            },
            {
              path: 'availability',
              name: 'availability',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'Availability' }],
                role: config.roles[1],
              },
              component: () => import('@/views/app/apps/AvailabilityView'),
            },
            {
              path: 'workload',
              name: 'workload',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'Workload' }],
                role: config.roles[1],
              },
              component: () => import('@/views/app/apps/Workload'),
            },
            {
              path: 'absence',
              name: 'absence',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'Absence' }],
                role: config.roles[1],
              },
              component: () => import('@/views/app/apps/Absence'),
            },
            {
              path: 'courses',
              name: 'courses',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'Courses' }],
                role: config.roles[1],
              },
              component: () => import('@/views/app/apps/Courses'),
            },
            {
              path: 'presence',
              name: 'presence-pma',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'PresencePMA' }],
                role: config.roles[1],
              },
              component: () => import('@/views/app/apps/PresencePMA'),
            },
            {
              path: 'link',
              name: 'link-pma',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'Link' }],
                role: config.roles[1],
              },
              component: () => import('@/views/app/apps/Link'),
            },
            {
              path: 'class/:id/student',
              name: 'students-list-pma',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'StudentsListPMA' }],
                role: config.roles[1],
              },
              component: () => import('@/views/app/apps/StudentsListPMA'),
            },
            {
              path: 'recusal',
              name: 'recusal',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'Ausstandsbegehren' }],
                role: config.roles[1],
              },
              component: () => import('@/views/app/apps/Recusal'),
            },
          ]
        },
        {
          path: 'app/author',
          component: () => import('@/views/app/Index'),
          beforeEnter: authenticate,
          children: [
            {
              path: 'fg-oral-questions',
              name: 'fg-oral-questions',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'OralQuestions' }],
                role: config.roles[2],
              },
              component: () => import('@/views/app/apps/FGQuestionsList'),
            },
            {
              path: 'ci-oral-questions',
              name: 'ci-oral-questions',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'ci-OralQuestions' }],
                role: config.roles[2],
              },
              component: () => import('@/views/app/apps/CIQuestionsList'),
            },
            {
              path: 'mini-cases',
              name: 'mini-cases',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'mini-cases' }],
                role: config.roles[2],
              },
              component: () => import('@/views/app/apps/MiniCasesTestsList'),
            },
            {
              path: 'author-document-list',
              name: 'author-document-list',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'AuthorDocuments' }],
                role: config.roles[2],
              },
              component: () => import('@/views/app/apps/AuthorDocuments'),
            },
            {
              path: 'questions-comments',
              name: 'questions-comments',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'QuestionsComments' }],
                role: config.roles[2],
              },
              props: true,
              component: () => import('@/views/app/apps/QuestionsComments'),
            },
            {
              path: 'questions-history',
              name: 'questions-history',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'QuestionsHistory' }],
                role: config.roles[2],
              },
              props: true,
              component: () => import('@/views/app/apps/QuestionsHistory'),
            },            
            {
              path: 'view-question',
              name: 'view-question',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'ViewQuestion'}],
                role: config.roles[2],
              },
              props: true,
              component: () => import('@/views/app/apps/ViewQuestion'),
            },
            {
              path: 'new-question',
              name: 'new-question',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'NewQuestion' }],
                role: config.roles[2],
              },
              props: true,
              component: () => import('@/views/app/apps/NewQuestion'),
            },
            {
              path: 'edit-question',
              name: 'edit-question',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'EditQuestion' }],
                role: config.roles[2],
              },
              props: true,
              component: () => import('@/views/app/apps/EditQuestion'),
            },       
            {
              path: 'test',
              name: 'test',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'Test' }],
                role: config.roles[2],
              },
              props: true,
              component: () => import('@/views/app/apps/TestView'),
            },         
          ],
        },
        {
          path: 'app/qsk',
          component: () => import('@/views/app/Index'),
          beforeEnter: authenticate,
          children: [
            {
              path: 'experts-record',
              name: 'experts-record',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'ExpertsRecord' }],
                role: config.roles[3],
              },
              component: () => import('@/views/app/apps/ExpertsRecord'),
            },
          ],
        },
        {
          path: 'app/student',
          component: () => import('@/views/app/Index'),
          beforeEnter: authenticate,
          children: [
            {
              path: 'file-manager',
              name: 'file-manager',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'File Manager' }],
                role: config.roles[0],
              },
              component: () => import('@/views/app/apps/FileManager'),
            },
            {
              path: 'link',
              name: 'link',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'Link' }],
                role: config.roles[0],
              },
              component: () => import('@/views/app/apps/Link'),
            },
            {
              path: 'timetable',
              name: 'timetable',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'Timetable' }],
                role: config.roles[0],
              },
              component: () => import('@/views/app/apps/Timetable'),
            },
            {
              path: 'presence',
              name: 'presence',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'Presence' }],
                role: config.roles[0],
              },
              component: () => import('@/views/app/apps/Presence'),
            },
            {
              path: 'exams',
              name: 'exams',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'Exams' }],
                role: config.roles[0],
              },
              component: () => import('@/views/app/apps/Exams'),
            },
            {
              path: 'final-marks',
              name: 'final-marks',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'FinalMarks' }],
                role: config.roles[0],
              },
              component: () => import('@/views/app/apps/FinalMarks'),
              props: router=> ({ChildAdressRolle: router.params.ChildAdressRolle}),
            },
            {
              path: 'semester-marks',
              name: 'semester-marks',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'SemesterMarks' }],
                role: config.roles[0],
              },
              component: () => import('@/views/app/apps/SemesterMarks'),
            },
            {
              path: 'quarter-marks',
              name: 'quarter-marks',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'QuarterMarks' }],
                role: config.roles[0],
              },
              component: () => import('@/views/app/apps/QuarterMarks'),
            },
            {
              path: 'experience-marks',
              name: 'experience-marks',
              meta: {
                breadcrumb: [{ name: 'Apps' }, { name: 'ExperienceMarks' }],
                role: config.roles[0],
              },
              component: () => import('@/views/app/apps/ExperienceMarks'),
            },
          ],
        },
        {
          path: 'app/sessions',
          component: () => import('@/views/app/sessions/Sessions'),
          children: [
            {
              path: 'error',
              component: () => import('@/views/app/sessions/Error'),
            },
            {
              path: 'register',
              name: 'register',
              component: () => import('@/views/app/sessions/RegisterUser'),
            },
            {
              path: 'validate-register/:token/:sid',
              name: 'validate-register',
              component: () => import('@/views/app/sessions/ValidateRegister'),
            },
            {
              path: 'reset-password-request',
              meta: {
                breadcrumb: [{ name: 'Forms' }, { name: 'reset-password-request' }],
              },
              component: () => import('@/views/app/sessions/ResetPasswordRequest'),
            },
            {
              path: 'user/:id/reset-password/:token',
              meta: {
                breadcrumb: [{ name: 'Forms' }, { name: 'reset-password' }],
              },
              component: () => import('@/views/app/sessions/ResetPassword'),
            },
          ],
        },
        {
          path: 'app/forms',
          component: () => import('@/views/app/Index'),
          children: [
            {
              path: 'change-password',
              meta: {
                breadcrumb: [{ name: 'Forms' }, { name: 'change-password' }],
              },
              component: () => import('@/views/app/forms/ChangePassword'),
            }
          ],
        },
        {
          path: 'app/dashboard',
          component: () => import('@/views/app/Index'),
          beforeEnter: authenticate,
          children: [
            {
              path: 'student',
              name: 'student',
              meta: {
                breadcrumb: [{ name: 'Dashboard' }, { name: 'Student' }],
                role: config.roles[0],
              },
              props: router=> ({ChildAdressRolle: router.params.ChildAdressRolle,ChildAddressID: router.params.ChildAddressID}),
              component: () => import('@/views/app/dashboard/Student'),
            },
            {
              path: 'parent',
              name: 'parent',
              meta: {
                breadcrumb: [{ name: 'Dashboard' }, { name: 'Parent' }],
                role: config.roles[2],
              },
              component: () => import('@/views/app/dashboard/Parent'),
            },
            {
              path: 'qsk',
              name: 'qsk',
              meta: {
                breadcrumb: [{ name: 'Dashboard' }, { name: 'Company' }],
              },
              component: () => import('@/views/app/dashboard/Company'),
            },
            {
              path: 'teacher',
              name: 'teacher',
              meta: {
                breadcrumb: [{ name: 'Dashboard' }, { name: 'Teacher' }],
              },
              component: () => import('@/views/app/dashboard/Teacher'),
            }
          ],
        },
        {
          path: '/',
          redirect: 'app/dashboard',
        },
      ]
    }
  ],
})

router.beforeEach(async (to, from, next) => {
  i18n.locale = to.params.locale;
  if (to.path) {
    // Start the route progress bar.
    store.dispatch('changeThemeLoadingState', true)
    if (to.path != '/callback' && to.path.indexOf('/app/sessions', 0) != 0) {
      localStorage.setItem('targetUrl', to.path)
    }
  }
  // If this isn't an initial page load.
  const b_IsTokenNull = await isTokenNull();
  if (to.path.indexOf(`/${to.params.locale}/admin-ms/sa`, 0) === 0 && b_IsTokenNull) {
    router.app.$smt.redirectAdmin()
    return
  }
  if (!router.app.$smt.isRouteFree(to) && b_IsTokenNull) {
    router.app.$smt.redirect()
  }
  if (to.path == `/${to.params.locale}/admin-ms/sa`) {
    next(`/${to.params.locale}/admin-ms/sa/select-user`)
  }
  else {
    next()
  }
})

router.afterEach(() => {
  // Remove initial loading
  // const gullPreLoading = document.getElementById("loading_wrap");
  // if (gullPreLoading) {
  //   gullPreLoading.style.display = "none";
  // }
  // Complete the animation of the route progress bar.
  setTimeout(() => store.dispatch('changeThemeLoadingState', false), 500)
  // NProgress.done();
  // if (isMobile) {
  // if (window.innerWidth <= 1200) {
  //   // console.log("mobile");
  //   store.dispatch("changeSidebarProperties");
  //   if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
  //     store.dispatch("changeSecondarySidebarProperties");
  //   }
  //   if (store.getters.getCompactSideBarToggleProperties.isSideNavOpen) {
  //     store.dispatch("changeCompactSidebarProperties");
  //   }
  // } else {
  //   if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
  //     store.dispatch("changeSecondarySidebarProperties");
  //   }
  //   // store.state.sidebarToggleProperties.isSecondarySideNavOpen = false;
  // }
})

export default router
